import React, {
	useState,
	useEffect,
	useCallback,
	useRef,
	useContext,
} from 'react';
import Nav from '../components/Nav';
import '../styles/Article.css';
import Footer from '../components/Footer';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../navigation/AuthProvider';
import { posted } from '../blogs/bloglist';
import { ToastContainer, toast } from 'react-toastify';

import firebase from 'firebase';
import placeholder from '../assets/images/placeholder.png';
import landscape from '../assets/images/placeholder-landscape.png';
import Icon from '@mdi/react';
import {
	mdiBookOpenPageVariantOutline,
	mdiFacebook,
	mdiTwitter,
	mdiLinkedin,
	mdiLinkVariant,
	mdiCommentTextOutline,
	mdiThumbUpOutline,
	mdiPlayCircleOutline
} from '@mdi/js';
import { Colors } from '../utils/utility';
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
} from "react-share";


import HandleKeywordEvents from '../blogs/articles/HandleKeywordEvents';
import ReactPropsAndState from '../blogs/articles/ReactPropsAndState';
import MasteringReactRedux from '../blogs/articles/MasteringReactRedux';
import ObjectOrientedProgramming from '../blogs/articles/ObjectOrientedProgramming';

const useMountedState = () => {
	const mountedRef = useRef(false);
	const isMounted = useCallback(() => mountedRef.current, []);

	useEffect(() => {
		mountedRef.current = true;
		return () => {
			mountedRef.current = false;
		};
	}, [isMounted]);

	return isMounted;
};

const Article = () => {
	const [comment, setComment] = useState({
		name: '',
		email: '',
		comment: '',
	});
	const navigate = useNavigate();
	const location = useLocation();
	const isMounted = useMountedState();
	const { blogId } = useParams();
	const { theme } = useContext(AuthContext);
	const [blog, setBlog] = useState({});
	const [tags, setTags] = useState([]);
	const [error, setError] = useState(false);

	const [stats, setStats] = useState({
		views: 0,
		likes: 0,
		comments: 0,
	});

	const title = 'Check out this awesome blog!';
	const [shareUrl, setShareUrl] = useState('');

	const defaultstring = `
		Hello, if you are seeing this, it means that the code snippet is not available yet. Please check back later.
    `;

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, [location]);

	useEffect(() => {
		document.title = `${blogId} | Syntax Tech Solutions`;
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		try {
			const blog = posted.find((blog) => blog.id === blogId);

			if (!blog) {
				isMounted() && setError(true);
			} else {
				isMounted() && setBlog(blog);
			}
		} catch (error) {
			isMounted() && setError(true);
		}
	}, [blogId, isMounted]);

	useEffect(() => {
		if (blog !== undefined) {
			onCountAsReadTrigger();
			countStats();
			const url = window.location.href;
			setShareUrl(url);
		}
		// eslint-disable-next-line
	}, [blog]);

	const loadTags = () => {
		if (blog.tag !== undefined) {
			let tags = blog.tag;

			let tagList = [];
			for (let i = 0; i < tags.length; i++) {
				tagList.push(tags[i]);
			}

			isMounted() && setTags(tagList);
		}
	};

	const onNavigate = (path) => {
		navigate(`/blog/${path}`);
	};

	useEffect(() => {
		if (blogId !== undefined) {
			loadTags();
		}
		// eslint-disable-next-line
	}, [blog]);

	// Article components
	const createHandleKeywordEvents = () => (
		<HandleKeywordEvents
			codestring={
				!error
					? blog.codestring !== undefined
						? blog.codestring.code
						: defaultstring
					: defaultstring
			}
		/>
	);

	const createPropsVsState = () => (
		<ReactPropsAndState
			codestringI={
				!error
					? blog.codestring !== undefined
						? blog.codestring[0].code
						: defaultstring
					: defaultstring
			}
			codestringII={
				!error
					? blog.codestring !== undefined
						? blog.codestring[1].code
						: defaultstring
					: defaultstring
			}
		/>
	);

	const createMasteringReactRedux = () => (
		<MasteringReactRedux
			codestringI={
				!error
					? blog.codestring !== undefined
						? blog.codestring[0].code
						: defaultstring
					: defaultstring
			}
			codestringII={
				!error
					? blog.codestring !== undefined
						? blog.codestring[1].code
						: defaultstring
					: defaultstring
			}
			codestringIII={
				!error
					? blog.codestring !== undefined
						? blog.codestring[2].code
						: defaultstring
					: defaultstring
			}
			codestringIV={
				!error
					? blog.codestring !== undefined
						? blog.codestring[3].code
						: defaultstring
					: defaultstring
			}
			codestringV={
				!error
					? blog.codestring !== undefined
						? blog.codestring[4].code
						: defaultstring
					: defaultstring
			}
		/>
	);

	const createObjectOrientedProgramming = () => (
		<ObjectOrientedProgramming
			AbstractionCode={
				!error
					? blog.codestring !== undefined
						? blog.codestring[0].code
						: defaultstring
					: defaultstring
			}
			EncapsulationCode={
				!error
					? blog.codestring !== undefined
						? blog.codestring[1].code
						: defaultstring
					: defaultstring
			}
			InheritanceCode={
				!error
					? blog.codestring !== undefined
						? blog.codestring[2].code
						: defaultstring
					: defaultstring
			}
			PolymorphismCode={
				!error
					? blog.codestring !== undefined
						? blog.codestring[3].code
						: defaultstring
					: defaultstring
			}
		/>
	);

	const handleChange = (event) => {
		setComment({
			...comment,
			[event.target.name]: event.target.value,
		});
	};

	const onComment = async () => {
		if (comment.name === '' || comment.email === '' || comment.comment === '') {
			toast.error('Please fill all fields', {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		} else {
			const timezone = 'Africa/Johannesburg';
			const currentDate = new Date();
			const options = {
				timeZone: timezone,
				weekday: 'long',
				year: 'numeric',
				month: 'long',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
			};
			const formattedDate = currentDate.toLocaleString('en-US', options);

			try {
				await firebase
					.firestore()
					.collection('blogs')
					.doc(blogId)
					.collection('comments')
					.add({
						Comment: comment.comment,
						Name: comment.name,
						Email: comment.email,
						Time: formattedDate,
					})
					.then(() => {
						setComment({
							name: '',
							email: '',
							comment: '',
						});

						toast.success('Comment posted', {
							position: 'top-center',
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
					});
			} catch (error) {
				console.log(error);
				toast.error('An error occured', {
					position: 'top-center',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			}
		}
	};

	const CreateArticle = () => {
		if (blog.code === 'HandleKeywordEvents') {
			return createHandleKeywordEvents();
		} else if (blog.code === 'PropsAndState') {
			return createPropsVsState();
		} else if (blog.code === 'MasteringReactRedux') {
			return createMasteringReactRedux();
		} else if (blog.code === 'ExploringObjectOrientedProgramming') {
			return createObjectOrientedProgramming();
		} else {
			return (
				<div className='notFound'>
					<h2>Article not found. </h2>
					<p>Please check the URL and try again.</p>
				</div>
			);
		}
	};

	const incrementBlogViews = () => {
		var blogRef = firebase.firestore().collection('blogs').doc(blogId);

		blogRef.update({
			views: firebase.firestore.FieldValue.increment(1)
		})
			.then(function () {
				console.log("Views count incremented successfully!");
			})
			.catch(function (error) {
				if (error.code === 'not-found') {
					blogRef.set({ views: 1 })
						.then(function () {
							console.log("Views count added successfully!");
						})
						.catch(function (error) {
							console.error("Error adding views count: ", error);
						});
				} else {
					console.error("Error incrementing views count: ", error);
				}
			});
	}

	const onHandleLike = async () => {
		var deviceDetails = getDeviceDetails();
		firebase.firestore()
			.collection('blogs')
			.doc(blogId)
			.collection("likes")
			.where("userAgent", "==", deviceDetails.userAgent)
			.get()
			.then(function (querySnapshot) {
				if (querySnapshot.empty) {
					firebase.firestore()
						.collection('blogs')
						.doc(blogId)
						.collection("likes")
						.add(deviceDetails)
						.then(function (docRef) {
							console.log("Device details added to Firestore with ID: ", docRef.id);
						})
						.catch(function (error) {
							console.error("Error adding device details to Firestore: ", error);
						});
				} else {

					querySnapshot.forEach(function (doc) {
						doc.ref
							.delete()
							.then(function () {
								console.log("Device details removed from Firestore");
							})
							.catch(function (error) {
								console.error("Error removing device details from Firestore: ", error);
							});
					});
				}
			})
			.catch(function (error) {
				console.error("Error querying Firestore: ", error);
			});

		countStats();
	}

	const countStats = () => {
		var stats = {
			likes: 0,
			views: 0,
			comments: 0
		};

		var blogRef = firebase.firestore().collection('blogs').doc(blogId);

		blogRef.collection('likes').get()
			.then(function (likesSnapshot) {
				stats.likes = likesSnapshot.size;

				blogRef.collection('comments').get()
					.then(function (commentsSnapshot) {
						stats.comments = commentsSnapshot.size;

						blogRef.get()
							.then(function (blogDoc) {
								if (blogDoc.exists) {
									var blogData = blogDoc.data();
									stats.views = blogData.views || 0;

									isMounted() && setStats(stats);
								}
							})
							.catch(function (error) {
								console.error("Error retrieving blog document: ", error);
							});
					})
					.catch(function (error) {
						console.error("Error retrieving comments count: ", error);
					});
			})
			.catch(function (error) {
				console.error("Error retrieving likes count: ", error);
			});
	}

	const getDeviceDetails = () => {
		var device = {
			userAgent: navigator.userAgent,
			browser: getBrowser(),
			operatingSystem: getOperatingSystem()
		};
		return device;
	}

	const getBrowser = () => {
		var userAgent = navigator.userAgent;
		var browsers = {
			Chrome: /Chrome/i,
			Safari: /Safari/i,
			Firefox: /Firefox/i,
			Edge: /Edge/i,
			IE: /Trident/i
		};

		for (var key in browsers) {
			if (browsers[key].test(userAgent)) {
				return key;
			}
		}
		return "Unknown";
	}

	const getOperatingSystem = () => {
		var userAgent = navigator.userAgent;
		var operatingSystems = {
			Windows: /Win/i,
			Mac: /Mac/i,
			Linux: /Linux/i,
			iOS: /iPhone|iPad|iPod/i,
			Android: /Android/i
		};

		for (var key in operatingSystems) {
			if (operatingSystems[key].test(userAgent)) {
				return key;
			}
		}
		return "Unknown";
	}

	const onCountAsReadTrigger = () => {
		var lastSave = JSON.parse(localStorage.getItem(blogId));
		var currentDate = new Date().toLocaleDateString();

		if (lastSave !== currentDate) {
			localStorage.setItem(blogId, JSON.stringify(currentDate));
			incrementBlogViews();
		}
	}

	const handleCopyLink = () => {
		const linkToCopy = window.location.href;
		console.log(linkToCopy)
		navigator.clipboard.writeText(linkToCopy)
			.then(() => {
				console.log('Link copied successfully!');
				toast.success('Link copied successfully!', {
					position: 'top-center',
					autoClose: 1000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.catch((error) => {
				console.error('Failed to copy link:', error);
				toast.error('Failed to copy link!', {
					position: 'top-center',
					autoClose: 1000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});
	};

	const handleShareToTwitter = () => {
		const element = document.getElementById('twitter');
		if (element) {
			element.click();
		}
	};

	const handleShareToFacebook = () => {
		const element = document.getElementById('facebook');
		if (element) {
			element.click();
		}
	};

	const handleShareToLinkedIn = () => {
		const element = document.getElementById('linkedin');
		if (element) {
			element.click();
		}
	};

	const handleListen = () => {
		toast.info('Hang tight! This feature is coming soon, so stay tuned for updates!', {
			position: 'top-center',
			autoClose: 1000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}




	return (
		<>
			<Nav />
			<main className='article-container'>
				<article className='content'>
					<header
						className={
							theme === 'dark-theme' ? 'article-header' : 'article-header light'
						}
					>
						{/* header */}
						<div className='article-header-inner'>
							<h1>{!error ? blog.title : 'Article not found'}</h1>
							<p>
								By{' '}
								<span>
									{!error
										? blog.author !== undefined
											? blog.author.name
											: 'Author not found'
										: 'Article not found'}
								</span>{' '}
								| <span>{!error ? blog.date : 'Article not found'}</span> {' '}
								| <span>{!error ? blog.readtime : '0 min read'}</span>
							</p>
							<div className='author-image'>
								<img
									src={
										!error
											? blog.author !== undefined
												? blog.author.image
												: placeholder
											: placeholder
									}
									alt='Author'
								/>
							</div>
							<div className='tags'>
								{!error &&
									tags.length > 0 &&
									tags.map((tag, index) => (
										<div className='tag' key={index}>
											<span>{tag}</span>
										</div>
									))}
							</div>

							<div className='stats'>
								<div className='side'>
									<div className='stat' data-title='Like'>
										<Icon
											path={mdiThumbUpOutline}
											color={theme === 'dark-theme' ? Colors.white : Colors.dark}
											size={'20px'}
											onClick={() => onHandleLike()}
										/>
										<span>{stats.likes}</span>
									</div>

									<div className='stat lg' data-title='Comments'>
										<Icon
											path={mdiCommentTextOutline}
											color={theme === 'dark-theme' ? Colors.white : Colors.dark}
											size={'20px'}
										/>
										<span>{stats.comments}</span>
									</div>

									<div className='stat' data-title='Read'>
										<Icon
											path={mdiBookOpenPageVariantOutline}
											color={theme === 'dark-theme' ? Colors.white : Colors.dark}
											size={'20px'}
										/>
										<span>{stats.views}</span>
									</div>
								</div>
								<div className='lg'>
									<div className='side'>
										<div className='stat' data-title='Listen' onClick={() => handleListen()}>
											<Icon
												path={mdiPlayCircleOutline}
												color={theme === 'dark-theme' ? Colors.white : Colors.dark}
												size={'30px'}
											/>
										</div>
									</div>
								</div>
								<div className='side lg'>
									<div className='stat' data-title='Copy Link' onClick={() => handleCopyLink()}>
										<Icon
											path={mdiLinkVariant}
											color={theme === 'dark-theme' ? Colors.white : Colors.dark}
											size={'20px'}
										/>
									</div>
									<div className='stat' data-title='Share on Facebook' onClick={handleShareToFacebook}>
										<Icon
											path={mdiFacebook}
											color={theme === 'dark-theme' ? Colors.white : Colors.dark}
											size={'20px'}
										/>
										<FacebookShareButton url={shareUrl} quote={title} className='hide' id='facebook' />
									</div>
									<div className='stat' data-title='Share on Twitter' onClick={handleShareToTwitter}>
										<Icon
											path={mdiTwitter}
											color={theme === 'dark-theme' ? Colors.white : Colors.dark}
											size={'20px'}
										/>
										<TwitterShareButton url={shareUrl} quote={title} className='hide' id='twitter' />
									</div>
									<div className='stat' data-title='Share on LinkedIn' onClick={handleShareToLinkedIn}>
										<Icon
											path={mdiLinkedin}
											color={theme === 'dark-theme' ? Colors.white : Colors.dark}
											size={'20px'}
										/>
										<LinkedinShareButton url={shareUrl} quote={title} className='hide' id='linkedin' />
									</div>
								</div>
							</div>
						</div>
					</header>
					<div className='article'>
						<div className='article-image' id='blog-read'>
							<img src={!error ? blog.image : landscape} alt='article' />
						</div>

						{/* Start of article */}

						{<CreateArticle />}

						{/* End of article */}

						<div className='underline' />

						<footer>
							<div className='related'>
								<h2>Other Articles:</h2>
								<ul>
									<li
										onClick={() =>
											onNavigate('how-to-handle-keyboard-events-in-react')
										}
									>
										How to Handle Keyboard Events in React: Best Practices and
										Code Examples.
									</li>
									<li onClick={() => onNavigate('props-vs-state-in-react')}>
										Props vs. State in React: Differences and Code Examples.
									</li>
									<li onClick={() => onNavigate('mastering-react-redux')}>
										Mastering React Redux: Best Practices for State Management
										that Will Streamline Your Development Process.
									</li>
								</ul>
							</div>

							<div className='underline' />

							<div className='comments'>
								<h2>Leave a comment:</h2>
								<form>
									<div className='form-group'>
										<label htmlFor='name'>Name</label>
										<input
											type='text'
											name='name'
											id='name'
											onChange={(e) => handleChange(e)}
											value={comment.name}
										/>
									</div>
									<div className='form-group'>
										<label htmlFor='email'>Email</label>
										<input
											type='email'
											name='email'
											id='email'
											onChange={(e) => handleChange(e)}
											value={comment.email}
										/>
										<span>
											We will never share your email with anyone else.
										</span>
									</div>
									<div className='form-group'>
										<label htmlFor='comment'>Comment</label>
										<textarea
											name='comment'
											id='comment'
											cols='30'
											rows='10'
											onChange={(e) => handleChange(e)}
											value={comment.comment}
										></textarea>
									</div>
									<button type='button' onClick={() => onComment()}>
										Post Comments
									</button>
								</form>
							</div>

							<div className='underline' />

							<div className='about-author'>
								<div className='author-image'>
									<img
										src={
											!error
												? blog.author !== undefined
													? blog.author.image
													: placeholder
												: placeholder
										}
										alt='Author'
									/>
								</div>
								<div className='about-author-text'>
									<h3>
										Hi! I'm{' '}
										{!error
											? blog.author !== undefined
												? blog.author.name
												: 'Author not found'
											: 'Article not found'}
									</h3>
									<p>
										{!error
											? blog.author !== undefined
												? blog.author.description
												: 'Author not found'
											: 'Article not found'}{' '}
										<span
											className='link'
											onClick={() =>
												window.open(
													`${!error &&
													blog.author !== undefined &&
													blog.author.link
													}`,
													'_blank'
												)
											}
										>
											LinkedIn
										</span>
									</p>
								</div>
							</div>
						</footer>
					</div>
				</article>
			</main>
			<Footer />
			<ToastContainer />
		</>
	);
};

export default Article;
