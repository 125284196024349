import React from 'react';
import './styles/ServiceCard.css';

const ServiceCard = ({ image, label, description }) => {
	return (
		<div className='service-card-container'>
			<div>
				<div className='icon'>
					<img src={image} alt={label} className='image' />
				</div>
			</div>
			<div>
				<h4 className='heading'>{label}</h4>
			</div>
			<div>
				<p className='paragraph'>{description}</p>
			</div>
		</div>
	);
};

export default ServiceCard;
