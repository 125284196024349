import React, { useEffect, useContext } from 'react';
import '../styles/Terms.css';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import { AuthContext } from '../navigation/AuthProvider';

const Terms = () => {
	const { theme } = useContext(AuthContext);
	useEffect(() => {
		document.title = `Terms of Services | Syntax Tech Solutions`;
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Nav />
			<main className='terms-container'>
				<header
					className={
						theme === 'dark-theme' ? 'terms-content' : 'terms-content light'
					}
				>
					<div className='heading'>
						<h1>Syntax Tech Soluton Terms of Services</h1>
						<p>
							These are Syntax Tech Solutions standard terms of service for any
							project. Have a good read through, and if anything jumps out at
							you as a potential issue, let us know before the project begins
							and we can discuss any alterations that might be needed for your
							particular project.
						</p>
					</div>
				</header>
				<section className='main-content'>
					<div className='content'>
						<h2>PAYMENT</h2>
						<h6>Deposits, final payments and delivery</h6>
						<p>
							We reserve the right to request a 33% deposit prior to starting
							work on your project. If a deposit is requested, an invoice will
							be generated and delivered to you via email and payment is
							expected within 14 days of issue. By remitting deposit funds you
							are accepting these Terms of Service and entering a contract with{' '}
							<b>Syntax Tech Solutions</b>.
						</p>
						<p>
							We will invoice for the remaining cost of the project and
							associated services prior to the release of any project files or
							final deployements. We reserve the right to withhold delivery
							until payment has been received in full.
						</p>
						<p>
							We reserve the right to invoice prior to the time detailed if you
							have been uncontactable/unresponsive for more than 14 days.
						</p>
						<p>
							You may reserve the right to request a payment plan which may be
							accepted at our discretion. All payment plans must be agreed to
							both parties in writing.
						</p>
						<p>All payments are to be made within 14 days of issue.</p>

						<p>
							We reserve the right to charge you for any fees incurred relating
							to commencement of collection and recovery processes for accounts
							that remain unpaid in excess of 14 days.
						</p>
					</div>

					<div className='content'>
						<h2>RESTRICTIONS</h2>
						<h6>Restrictions to the signing of this agreement</h6>
						<p>
							These Terms of Service do not give you the rights or permission to
							use/modify/alter/replicate or borrow any of the previous
							ideas/concepts/sketches that I present. All original preparation
							materials, sketches, visuals and unused ideas shown and considered
							will remain the property of <b>Syntax Tech Solutions</b>.
						</p>
						<p>
							We are free to use these unused and previous ideas for future
							conceptual and client work. Where a previously unused idea/design
							retains a similar look and style to the finished project, We shall
							repurpose and style so that the end design is sufficiently
							different as to not cause conflict.
						</p>
						<p>
							Unless otherwise agreed and arranged, We reserve the right to
							showcase the finished project and associated designs in our
							portfolio and in any number of online
							galleries/portfolio/showcases/awards as well as in printed
							literature including books and magazines, now and in the future.
							Associated designs and artwork can include commercial print
							design/business card/stationery design/signage/desktop
							icons/mobile phone and other portable device application icons and
							imagery.
						</p>
						<p>
							ANY supporting artwork and designs required by this project can be
							used as indicated by myself for personal and professional reasons.
						</p>

						<p>
							You are free to change, modify and adapt the finished project
							design as you see fit, but you do so at your own risk.
						</p>
					</div>

					<div className='content'>
						<h2>OWNERSHIP & COPYRIGHT</h2>
						<p>
							All preparation materials, sketches, visuals, including the
							electronic files used to create the project remain the property of{' '}
							<b>Syntax Tech Solutions</b>. The final artwork/digital files will
							become your property ONLY upon final payment of the project.
						</p>
						<p>
							If final payment is NOT received as agreed, all designs and
							concepts will remain the property of <b>Syntax Tech Solutions</b>{' '}
							until payment is received.
						</p>
						<p>
							If there are issues with final payment, We reserve the right to
							reuse or amend any of these ideas for other clients, or to be used
							freely as concepts in my portfolio.
						</p>
						<p>
							Should the client attempt to use/modify/alter/replicate or steal
							any of our ideas without making agreed final payment, I will take
							immediate legal counsel.
						</p>

						<p>
							<b>Syntax Tech Solutions</b> reserves the right to show any
							artwork, ideas, sketches created for this project in a portfolio
							as examples of client work. This is typically, but not limited to
							the completion of the project. If you have any specific
							‘secrecy/stealth mode’ or NDA requirements, please mention this
							before agreeing to the proposal.
						</p>

						<h6>
							Final payment ensures that ONLY the agreed project becomes your
							property. Any previous ideas/concepts remain ours, unless any
							prior agreement has been made.
						</h6>
					</div>
					<div className='content'>
						<h2>CANCELLATION</h2>
						<h6>Cancellation during the project</h6>
						<p>
							If you choose to cancel the project midway through, where ideas
							and proposals have been submitted, refund of previous payment is
							not possible. However, depending on the work completed and overall
							budget, a portion of the funds may be returned.
						</p>
						<p>
							If our employee assigned to you project falls ill, or unable to
							complete the project due to unforeseen circumstances, a portion of
							the overall budget will be returned. In most cases the complete
							amount will be refunded. If any works, so far completed, can be
							used for another person to pick up, then a percentage will be
							refunded based on work completed or any other reasonable
							suggestion will be considered.
						</p>

						<h6>Project Suspension</h6>
						<p>
							We reserve the right to suspend any project if there is
							interference with excessive micromanaging, demonstrating a
							continued lack of trust and inability to move forward after
							showing more than a reasonable number of unique project
							ideas/concepts, and/or showing a reluctance in paying the final
							payment.
						</p>
						<p>
							Fair notice will be given with fair chance to remedy the situation
							without resorting to project suspension or termination. Any
							suspension, or termination, will not result in any refunds and all
							designs and work thus developed remain the full ownership of{' '}
							<b>Syntax Tech Solutions</b>.
						</p>

						<h6>Force Majeure</h6>
						<p>
							In the event we fail to perform any obligation pursuant to these
							Terms of Service due to an “act of God” or an act of any
							government, terrorism, riot, war, accident or any deficiency in
							materials or transportation or any other cause of any nature
							beyond our control, such failure shall not be deemed to be a
							breach of these Terms of Service, provided that you are notified
							of the existence and nature of the reason for our non-performance
							and delay, and we resume performance immediately upon the
							conclusion of the relevant force majeure.
						</p>
					</div>
					<div className='content'>
						<h2>LIMITATION OF LIABILITY</h2>
						<h6>Loss or damage</h6>
						<p>
							You agree and accept that <b>Syntax Tech Solutions</b> is not
							legally responsible for any loss or damage suffered or incurred
							related to use of any of our services, whether from amendments,
							errors or omissions in documents, designs, information or any
							goods or services offered by us. This includes your use or
							reliance on any third-party content, links, comments or
							advertisements. Your use of, or reliance on, any information or
							materials we produce, amends or designs is entirely at your own
							risk, for which we shall not be liable.
						</p>
						<p>
							You acknowledge that such information and materials may contain
							inaccuracies or errors and expressly exclude liability of{' '}
							<b>Syntax Tech Solutions</b> for any such inaccuracies or errors
							to the fullest extent permitted by law.
						</p>
						<p>
							We affirm that all designs presented to you will be original and
							to the best of our knowledge will not infringe/plagiarise any
							other work. We will perform limited checks to ensure that our work
							has not unintentionally infringed on another’s design, including
							limited checks against the WIPO and CIPC database and reverse
							image checks across search engines. However, We assume no legal
							responsibility for any loss or damage suffered or incurred related
							to legal issues regarding the originality or authenticity of our
							work. You agree to perform your own checks and due diligence
							regarding plagiarism and originality. Should you have any
							concerns, please discuss this item with us prior to commencing
							work.
						</p>
					</div>
					<div className='content'>
						<h2>TRADEMARK & COPYRIGHT</h2>
						<p>
							Due to the lengthy and often costly procedures required to
							initiate any form of Trademark, Copyright and legal name search, I
							we unable provide any practical help with this.
						</p>
						<p>
							If you require the website logo, application logo or anything to
							be registered as a trademark, then you must seek your own legal
							advice.
						</p>

						<h2>BRAND NAMING</h2>
						<p>
							You take full responsibility for ensuring that your
							company/product/name is legally free/available before work is
							started. Should any legal issue arise with the naming after the
							project has been completed, no refunds are possible, neither are
							we legally responsible for any problems thus arising.
						</p>

						<p>
							Please ensure the name you are using is free and legally safe to
							use before committing to a project. Project name changes during a
							project are exceptionally costly, challenging to implement, and
							cause significant delays.
						</p>
						<p>
							If a change of name is required mid-way during a project, and
							significant project exploration work has already been presented,
							then we will have no choice but to recalculate the total cost of
							the project, to allow for the reworking involved. Please ensure
							that the name you are using has been subject to appropriate
							checks, is not in use by someone else, and/or infringes on any
							other registered trademark, business name etc.
						</p>
					</div>
				</section>
			</main>
			<Footer />
		</>
	);
};

export default Terms;
