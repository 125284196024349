import React from 'react'

import CodeMirror from '@uiw/react-codemirror';
import { createTheme } from '@uiw/codemirror-themes';
import { javascript } from '@codemirror/lang-javascript';
import { tags as t } from '@lezer/highlight';

const DarkTheme = createTheme({
    theme: 'dark',
    settings: {
        background: '#1e1e1e',
        foreground: '#e6e6e6',
        caret: '#9f9f9f',
        selection: '#add6ff33',
        selectionMatch: '#add6ff33',
        lineHighlight: '#3f3f3f',
        gutterBackground: '#1e1e1e',
        gutterForeground: '#9f9f9f',
    },
    styles: [
        { tag: t.comment, color: '#6a9955' },
        { tag: t.variableName, color: '#9cdcfe' },
        { tag: [t.string, t.special(t.brace)], color: '#ce9178' },
        { tag: t.number, color: '#b5cea8' },
        { tag: t.bool, color: '#4ec9b0' },
        { tag: t.null, color: '#4ec9b0' },
        { tag: t.keyword, color: '#c586c0' },
        { tag: t.operator, color: '#d4d4d4' },
        { tag: t.className, color: '#4ec9b0' },
        { tag: t.definition(t.typeName), color: '#4ec9b0' },
        { tag: t.typeName, color: '#4ec9b0' },
        { tag: t.angleBracket, color: '#d4d4d4' },
        { tag: t.tagName, color: '#c586c0' },
        { tag: t.attributeName, color: '#9cdcfe' },
    ],
});
const extensions = [javascript({ jsx: true })];

const ObjectOrientedProgramming = ({
    AbstractionCode,
    EncapsulationCode,
    InheritanceCode,
    PolymorphismCode,
}) => {
    return (
        <>
            <div className='breakpoint' />
            <p>
                Ever since you embarked on your programming journey, you might have heard the term "OOP" thrown around. It's often presented as a powerful programming concept, but what exactly is it? Don't worry; this blog is here to demystify Object-Oriented Programming (OOP) in the most straightforward and relatable way possible. Get ready to dive into the world of OOP, where code becomes more intuitive, flexible, and fun!
            </p>
            <p>
                Before we jump into the code, let's grasp the foundational principles of OOP that drive its power. To make things more memorable, we'll borrow the first three vowels from the alphabet and the letter "P" from "OOP." Let's meet our OOP superhero team: A, E, I, and P!
            </p>
            <p>
                Think of the world around you. It's filled with various objects, each with unique characteristics and behaviours. For instance, consider a car. A car has attributes like its colour, model, and horsepower. It can perform actions like starting the engine, accelerating, and braking. Now, let's map this real-life concept to the world of programming.
            </p>
            <p>
                In OOP, we treat objects as the building blocks of our code. These code-based objects have properties (attributes) and behaviours (actions) like real-world objects. They allow us to model and interact with complex systems in a way that mirrors the real world. OOP helps us organize and manage our code more intuitively and human-friendly.
            </p>
            <h2>
                Abstraction (A) - Hiding the Complexity
            </h2>
            <p>
                Imagine you're driving a car. You don't have to worry about how the engine works internally. Instead, you focus on important tasks like accelerating, braking, and steering. This concept is called abstraction! In Object-Oriented Programming (OOP), abstraction lets us concentrate on the essential aspects of an object while hiding the complicated details inside.
            </p>
            <div className='code'>
                <CodeMirror
                    language='javascript'
                    theme={DarkTheme}
                    extensions={extensions}
                    readOnly={true}
                    linenumbers="true"
                    styleactiveline="true"
                    value={AbstractionCode}
                />
            </div>
            <p>
                In this example, we have an abstract class called <span className='commands'>`Vehicle`</span>, which represents a general concept of a vehicle. It has two abstract methods, <span className='commands'>`Accelerate()`</span> and <span className='commands'>`Brake()`</span>, that define the basic behavior of a vehicle. The <span className='commands'>`Car`</span> class inherits from <span className='commands'>`Vehicle`</span> and provides specific implementations for the <span className='commands'>`Accelerate()`</span> and <span className='commands'>`Brake()`</span> methods. When we create an instance of the <span className='commands'>`Car`</span> class and call these methods, we get the corresponding output.
            </p>

            <div className='breakpoint' />

            <h2>
                Encapsulation (E) - Bundling Data and Behavior Together
            </h2>
            <p>
                Encapsulation in Object-Oriented Programming (OOP) is like keeping things organized and safe in a locked box. In programming, we group related information and actions together inside an object. It's similar to putting valuable items in a treasure chest and locking it to prevent anyone from accessing or changing them without permission.
                <br /><br />
                Encapsulation helps us protect our code and prevent accidental changes. It keeps data and actions together, like a shield around an object, so that other parts of the program can't easily mess with them. This keeps our code organized, makes it easier to understand and maintain, and allows us to reuse objects in different parts of the program without causing problems.
                <br /><br />
                Think of encapsulation as a way to keep things secure and organized, like a locked treasure chest for our code. It helps us control access to our data and actions, making our programs more reliable and easier to work with.
            </p>
            <div className='code'>
                <CodeMirror
                    language='javascript'
                    theme={DarkTheme}
                    extensions={extensions}
                    readOnly={true}
                    linenumbers="true"
                    styleactiveline="true"
                    value={EncapsulationCode}
                />
            </div>
            <p>
                In this example, we have a <span className='commands'>`BankAccount`</span>, class that demonstrates encapsulation. The <span className='commands'>`balance`</span> field is declared as private, meaning it cannot be accessed directly from outside the class. Instead, we provide public methods (<span className='commands'>`Deposit()`</span> and <span className='commands'>`Withdraw()`</span>) to interact with the <span className='commands'>`balance`</span> field. The <span className='commands'>`Deposit()`</span> method allows us to add money to the account, and the <span className='commands'>`Withdraw()`</span> method deducts the specified amount from the account, checking for sufficient funds before making the withdrawal.
            </p>

            <div className='breakpoint' />

            <h2>Inheritance (I) - The Art of Reusing and Extending</h2>
            <p>
                Imagine you have a set of building instructions for a house. Now, you want to build different types of houses based on those instructions. In Object-Oriented Programming (OOP), inheritance works like using those instructions to create new houses with some unique features.
                <br /><br />
                Let's think about animals instead. Suppose we have a basic animal class that describes common things animals have, like legs and the ability to eat. Now, we can make new classes for specific animals like dogs and cats. These new classes inherit the characteristics from the basic animal class but can also have their own special traits, like the sound they make.
                <br /><br />
                By using inheritance, we can reuse the common traits defined in the animal class, like legs and eating, for all animals. At the same time, we can add specific traits to each animal class, like the sound a dog or a cat makes. This way, we save time by not repeating the same traits for every animal and make it easier to organize different classes of animals.
            </p>
            <div className='code'>
                <CodeMirror
                    language='javascript'
                    theme={DarkTheme}
                    extensions={extensions}
                    readOnly={true}
                    linenumbers="true"
                    styleactiveline="true"
                    value={InheritanceCode}
                />
            </div>
            <p>
                In this example, we have a base class called <span className='commands'>`Animal`</span>, which represents a general animal. It has a virtual method <span className='commands'>`MakeSound()`</span> that provides a default implementation for making a sound. The <span className='commands'>`Dog`</span> and <span className='commands'>`Cat`</span> classes inherit from <span className='commands'>`Animal`</span> and override the <span className='commands'>`MakeSound()`</span> method with their specific sound implementations. When we create instances of <span className='commands'>`Dog`</span> and <span className='commands'>`Cat`</span> and call the<span className='commands'>`MakeSound()`</span> method, we get the corresponding animal sounds.
            </p>
            <div className='breakpoint' />

            <h2>
                Polymorphism (P) - The Shape-Shifting Superpower
            </h2>
            <p>
                Our final superpower, Polymorphism, enables objects to take on multiple forms. Just like a superhero adapting to different situations, polymorphism allows us to treat objects of different types as if they belong to a common base class. This flexibility enables us to write more generic and reusable code.
            </p>
            <div className='code'>
                <CodeMirror
                    language='javascript'
                    theme={DarkTheme}
                    extensions={extensions}
                    readOnly={true}
                    linenumbers="true"
                    styleactiveline="true"
                    value={PolymorphismCode}
                />
            </div>
            <p>
                In this example, we have a base class called <span className='commands'>`Shape`</span>, which represents a generic shape. It has a virtual method <span className='commands'>`Draw()`</span> that provides a default implementation for drawing a shape. The <span className='commands'>`Circle`</span> and <span className='commands'>`Square`</span> classes inherit from <span className='commands'>`Shape`</span> and override the <span className='commands'>`Draw()`</span> method with their specific drawing implementations. When we create instances of <span className='commands'>`Circle`</span> and <span className='commands'>`Square`</span> and call the <span className='commands'>`Draw()`</span> method, we get the corresponding shapes drawn.
            </p>
            <div className='breakpoint' />
            <p>
                Congratulations! You've just unleashed the power of Object-Oriented Programming (OOP). By embracing the principles of abstraction, encapsulation, inheritance, and polymorphism, you have gained valuable tools to design intuitive and maintainable code. Remember, OOP is not just about writing code; it's about thinking in objects and leveraging real-life concepts to create elegant and efficient solutions. So <span className='commands'>"IF YOU DONT GERRIT,FORGET ABOUT IT!"</span> I'm kidding man read this again, like the post, and leave a comment, code with joy, and let your creativity soar in the world of OOP!
            </p>

            <p>
                <b> <span className='commands'>Disclaimer:</span> </b>This blog aims to provide an accessible introduction to Object-Oriented Programming (OOP) using C# code snippets. It is designed for undergraduate students and anyone interested in learning OOP concepts in a playful and relatable manner.
            </p>

        </>
    )
}

export default ObjectOrientedProgramming