import React, { useEffect, useContext } from 'react';
import '../styles/Vibes.css';
import { AuthContext } from '../navigation/AuthProvider';

const VibesCheck = () => {
    const { theme } = useContext(AuthContext);
    useEffect(() => {
        document.title = `Privacy Policy for Vibes Check`;
    }, []);

    return (
        <>
            <main className='vibes-container'>
                <header
                    className={
                        theme === 'dark-theme' ? 'terms-content' : 'terms-content light'
                    }
                >
                    <div className='heading'>
                        <h1>
                            Privacy Policy for Vibes Check
                        </h1>
                        <p>
                            Welcome to Vibes Check, the dating app that helps you connect with like-minded individuals based on your interests and vibes.
                            Your privacy is crucial to us, and this Privacy Policy outlines how we collect, use, store, and protect your personal information.
                            This Privacy Policy applies to Vibes Check, owned and operated by Vbes Check PTY LTD, hereinafter referred to as 'We' or 'Us'.
                        </p>
                        <p>
                            Effective date: <b>02 February 2024</b>
                        </p>
                    </div>
                </header>
                <section className='main-content'>
                    <div className='content'>
                        <h2>Information We Collect</h2>
                        <h6>
                            When you use Vibes Check, we collect the following types of information from you:
                        </h6>
                        <ul>
                            <li><b>Name:</b> To identify your account and profile.</li>
                            <li><b>Birth Date:</b> To ensure you meet the age requirement for using our app.</li>
                            <li><b>Gender:</b> To help personalize your app experience.</li>
                            <li><b>Phone Number:</b> For essential communication.</li>
                            <li><b>Email Address:</b> For account management and communication.</li>
                            <li><b>Location:</b> To enable you to connect with nearby users.</li>
                            <li><b>Multimedia Files:</b> One 30-second video and three images to enrich your profile and enhance the user experience.</li>
                        </ul>
                        <p>
                            This information is essential for providing the services and features offered by Vibes Check and enhancing your experience on our platform.
                        </p>

                    </div>

                    <div className='content'>
                        <h2>How We Use Your Information</h2>
                        <h6>
                            Your data is used exclusively for internal purposes, such as:
                        </h6>
                        <ul>
                            <li>Providing, operating, and maintaining the Vibes Check services.</li>
                            <li>Improving, personalizing, and expanding our app.</li>
                            <li>Understanding and analyzing how you use our app.</li>
                            <li>Developing new products, services, features, and functionality.</li>
                        </ul>
                    </div>

                    <div className='content' id='delete'>
                        <h2>
                            Deleting Your Profile
                        </h2>
                        <h6>
                            You have the right to delete your profile and all associated data from Vibes Check. To do so:
                        </h6>
                        <ol>
                            <li>Go to your Profile when logged in.</li>
                            <li>Select "Delete Profile."</li>
                            <li>Type "DELETE" to confirm your intent.</li>
                            <li>Confirm your choice; your profile and all associated data will be permanently deleted.</li>
                        </ol>

                        <p>
                            Please note that it cannot be recovered once your profile is deleted.
                        </p>
                    </div>

                    <div className='content'>
                        <h2>
                            Changes to This Privacy Policy
                        </h2>
                        <p>
                            We may update our Privacy Policy from time to time.
                            We will notify you of any changes by posting the new Privacy Policy on this page.
                            You are advised to review this Privacy Policy periodically for any changes.
                        </p>
                    </div>

                    <div className='content'>
                        <h2>
                            Contact Us
                        </h2>
                        <p>
                            If you have any questions or suggestions about our Privacy Policy, please contact us:
                        </p>
                        <ul>
                            <li>By email: <a href='mailto:mtswenisabelo301@gmail.com'>
                                mtswenisabelo301@gmail.com
                            </a></li>
                        </ul>
                        <p>
                            By using Vibes Check, you consent to this policy's collection and use of information.
                        </p>
                    </div>
                </section>
            </main>
        </>
    )
}

export default VibesCheck