import React, { useState } from 'react';
import Button from '../components/Button';
import CountUp from 'react-countup';
import FormModal from '../components/FormModal';
import ScrollTrigger from 'react-scroll-trigger';
import Image from '../assets/images/pakata-goh_1.jpg';
import '../styles/Main.css';

const Main = () => {
	const [counterOn, setCounterOn] = useState(false);
	const [visible, setVisible] = useState(false);

	const onPressed = () => {
		setVisible(!visible);
	};
	return (
		<>
			<ScrollTrigger
				onEnter={() => setCounterOn(true)}
				onExit={() => setCounterOn(false)}
			>
				<section className='main' data-aos='fade-right'>
					<div className='textContainer'>
						<p className='text'>A TRUSTED SOFTWARE DEVELOPEMENT COMPANY</p>
						<h4 className='bold'>Quality Software Services You Really Want</h4>
						<p className='secondText'>
							We deliver top-level custom software design and development
							services to help our clients start, run and grow their businesses.
						</p>
						<Button title={"LET'S TALK"} onPressed={() => onPressed()} />
					</div>
					<div className='imageContainer'>
						<img src={Image} alt='main' className='image' />
					</div>

					<div className='statsContainer'>
						<div className='stats'>
							<h5 className='number'>
								{counterOn && (
									<CountUp end={5} duration={2} suffix='+' delay={0} />
								)}
							</h5>
							<p className='label'>Years of Experience</p>
						</div>
						<div className='stats'>
							<h5 className='number'>
								{counterOn && (
									<CountUp end={40} duration={2} suffix='+' delay={0} />
								)}
							</h5>
							<p className='label'>Happy Clients</p>
						</div>
						<div className='stats'>
							<h5 className='number'>
								{counterOn && (
									<CountUp end={43} duration={2} suffix='+' delay={0} />
								)}
							</h5>
							<p className='label'>Finished Projects</p>
						</div>
					</div>
				</section>
			</ScrollTrigger>
			<FormModal close={visible} />
		</>
	);
};

export default Main;
