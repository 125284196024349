export const Colors = {
	white: '#F4F6FC',
	dark: '#1D1E2A',
	yellow: '#FAD862',
	gray: '#F4F6FC10',
	brown: '#7d6c31',
};

export const Fonts = {
	Regular: 'Urbanist-Regular',
	Medium: 'Unranist-Medium',
	Semibold: 'Urbanist-Semibold',
	Bold: 'Urbanist-Bold',
};
