import React, { useContext, useEffect, useState } from 'react';
import './styles/Form.css';
import { Colors } from '../utils/utility';
import { AuthContext } from '../navigation/AuthProvider';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

const FormModal = ({ close }) => {
	const { theme } = useContext(AuthContext);
	const [visible, setVisible] = useState('none');

	useEffect(() => {
		close && setVisible('flex');
	}, [close]);

	const sendMessage = (event) => {
		event.preventDefault();

		emailjs
			.sendForm(
				'syntaxtechsolutions',
				'template_syntax',
				event.target,
				'user_vqapCNsTGfo7dazQYEl5e'
			)
			.then(
				(result) => {
					console.log(result.text);
					toast.success('Message Sent', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
					setVisible('none');
				},
				(error) => {
					console.log(error.text);
					toast.error('Something went wrong', {
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
					setVisible('none');
				}
			);
		event.target.reset();
	};

	return (
		<>
			<section className='form-container' style={{ display: visible }}>
				<div className='container'>
					<div className='modal'>
						<Icon
							path={mdiClose}
							title='light'
							size={'24px'}
							color={theme === 'dark-theme' ? Colors.white : Colors.dark}
							className='CloseIcon'
							onClick={() => setVisible('none')}
						/>
						<h4 className='heading'>
							Get In Touch With Us For More Information
						</h4>
						<form onSubmit={sendMessage}>
							<div className='field'>
								<input type='text' placeholder='Name' name='name' required />
							</div>
							<div className='field'>
								<input type='email' placeholder='Email' name='email' required />
							</div>
							<div className='field'>
								<input
									type='text'
									placeholder='Subject'
									name='subject'
									required
								/>
							</div>
							<div className='field textarea'>
								<textarea
									cols='30'
									rows='10'
									placeholder='Message...'
									name='message'
									required
								></textarea>
							</div>
							<div className='button-area'>
								<button type='submit'>Send message</button>
							</div>
						</form>
					</div>
				</div>
			</section>
			<ToastContainer />
		</>
	);
};

export default FormModal;
