import React, { useState, createContext, useEffect } from 'react';
import firebase from 'firebase';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [theme, setTheme] = useState('dark-theme');

	const toggleTheme = () => {
		theme === 'dark-theme' ? saveTheme('light-theme') : saveTheme('dark-theme');
	};

	useEffect(() => {
		document.body.className = theme;
	}, [theme]);

	useEffect(() => {
		getTheme();
	}, []);

	const getTheme = async () => {
		const result = localStorage.getItem('Theme');
		if (result !== null) {
			setTheme(result);
		}
	};

	const login = async (Email, Password) => {
		try {
			await firebase.auth().signInWithEmailAndPassword(Email, Password);
		} catch (e) {
			return alert('Authentication Error', `${e.message}`);
		}
	};

	const register = async (Name, Email, Password) => {
		try {
			await firebase
				.auth()
				.createUserWithEmailAndPassword(Email, Password)
				.then(() => {
					firebase
						.firestore()
						.collection('Users')
						.doc(firebase.auth().currentUser.uid)
						.set({
							Name,
							Email,
							Date: new Date(),
						});
				});
		} catch (e) {
			return alert('Authentication Error', `${e.message}`);
		}
	};

	const forgot = async (email) => {
		try {
			await firebase
				.auth()
				.sendPasswordResetEmail(email)
				.then(() => {
					return alert(
						'Email Sent',
						'Please check your email, You will get a link to reset your password.'
					);
				});
		} catch (e) {
			return alert('Authentication Error', `${e.message}`);
		}
	};

	const logout = async () => {
		try {
			await firebase.auth().signOut();
		} catch (e) {
			return alert('Authentication Error', `${e.message}`);
		}
	};

	const saveTheme = async (theme) => {
		try {
			localStorage.setItem('Theme', theme);
			setTheme(theme);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<AuthContext.Provider
			value={{
				user,
				setUser,
				login,
				register,
				forgot,
				logout,
				theme,
				toggleTheme,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
