import React, { useEffect } from 'react';
import '../styles/About.css';
import image from '../assets/images/syntax.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);

	return (
		<section className='about-container' id='about' data-aos='fade-left'>
			<p className='text'>ABOUT</p>
			<h4 className='bold'>WHO ARE WE</h4>
			<div className='flex'>
				<div className='imageContainer'>
					<img src={image} alt='syntax' className='image' />
				</div>
				<div className='paragraph'>
					<p>
						<strong>Syntax Tech Solutions</strong> is an end-to-end custom software development
						company that designs, builds, deploy and maintain mobile and web
						application.
					</p>
					<p>
						Syntax Tech Solutions provides complete software design and
						development services. We deliver the best custom mobile applications
						and websites to help our clients start, run and grow their
						businesses. We also offer testing services for your mobile and web
						applications.
					</p>
					<p>
						We specialize in building unique digital experiences for our clients
						- from websites to special-purpose mobile applications. We deliver
						the best, usable, professional websites using best practice
						accessibility and the latest W3C web standards guidelines, resulting
						in semantic and SEO-friendly websites.
					</p>
					<p>
						We develop mobile applications that follow material design
						guidelines for visual and navigation patterns. And we understand
						that Consistent, intuitive app user experiences are more important
						than ever to grow your business.
					</p>

					<div>
						<p><b>Let's Build the Future Together</b></p>
						<p>
							Are you ready to elevate your business with cutting-edge software solutions?
							Contact us today to start your journey with Syntax Tech Solutions, where innovation meets excellence.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default About;
