import React from 'react';

import CodeMirror from '@uiw/react-codemirror';
import { createTheme } from '@uiw/codemirror-themes';
import { javascript } from '@codemirror/lang-javascript';
import { tags as t } from '@lezer/highlight';

const DarkTheme = createTheme({
	theme: 'dark',
	settings: {
		background: '#1e1e1e',
		foreground: '#e6e6e6',
		caret: '#9f9f9f',
		selection: '#add6ff33',
		selectionMatch: '#add6ff33',
		lineHighlight: '#3f3f3f',
		gutterBackground: '#1e1e1e',
		gutterForeground: '#9f9f9f',
	},
	styles: [
		{ tag: t.comment, color: '#6a9955' },
		{ tag: t.variableName, color: '#9cdcfe' },
		{ tag: [t.string, t.special(t.brace)], color: '#ce9178' },
		{ tag: t.number, color: '#b5cea8' },
		{ tag: t.bool, color: '#4ec9b0' },
		{ tag: t.null, color: '#4ec9b0' },
		{ tag: t.keyword, color: '#c586c0' },
		{ tag: t.operator, color: '#d4d4d4' },
		{ tag: t.className, color: '#4ec9b0' },
		{ tag: t.definition(t.typeName), color: '#4ec9b0' },
		{ tag: t.typeName, color: '#4ec9b0' },
		{ tag: t.angleBracket, color: '#d4d4d4' },
		{ tag: t.tagName, color: '#c586c0' },
		{ tag: t.attributeName, color: '#9cdcfe' },
	],
});
const extensions = [javascript({ jsx: true })];

const MasteringReactRedux = ({
	codestringI,
	codestringII,
	codestringIII,
	codestringIV,
	codestringV,
}) => {
	return (
		<>
			<div className='breakpoint' />
			<p>
				Redux is a powerful state management library that has become a
				cornerstone in modern web development, particularly in building complex
				single-page applications (SPAs) with React. Redux allows developers to
				manage and organize application states in a predictable and centralized
				way, making debugging, testing, and scaling applications easier.
			</p>
			<p>
				When choosing a state management library for a React or React Native
				application, there is no one-size-fits-all solution. While some
				developers may opt for alternative libraries or even build their custom
				solution, React-Redux remains a popular choice among many. Its mature
				and well-established status, predictable state management, large
				ecosystem, performance optimizations, and debugging tools are why React
				developers continue to turn to it. This blog post will examine why
				React-Redux is a popular choice and whether it fits your next project.
			</p>

			<p>
				Complete basic app:{' '}
				<span
					className='link'
					onClick={() =>
						window.open(
							`https://github.com/kibambe-0167/blog-reactjs-redux`,
							'_blank'
						)
					}
				>
					https://github.com/kibambe-0167/blog-reactjs-redux
				</span>
			</p>

			<div className='breakpoint' />
			<h2>Create a React JS app</h2>
			<div className='underline' />
			<p>I like using yarn package manager, it is faster.</p>
			<span className='commands'>{`yarn create react-app <app-name>`}</span>

			<div className='breakpoint' />
			<p>install react redux, in your reactjs app</p>
			<span className='commands'>yarn add react-redux</span>

			<div className='breakpoint' />
			<p>Install @reduxjs/toolkit. </p>
			<span className='commands'>yarn add @reduxjs/toolkit</span>

			<div className='breakpoint' />
			<p>
				Create a configure file <span className='commands'>(store.js)</span>.
				The file might be placed wherever you prefer in your file structure.
				Export a react redux store made with configureStore function.
				configureStore is made to provide a store and it also comes with some
				redux middleware. The object with{' '}
				<span className='commands'>‘reducer’</span> passed to the configureStore
				function specifies the root reducer for the store. ‘taskReducer’
				assigned to <span className='commands'>‘tasks’</span> handles actions
				related to ‘tasks’ slice of the redux store. This code sets up a redux
				store with a single reducer, which can be used to manage state in a
				redux application, and is ready to be integrated with a react
				application that uses the <span className='commands'>react-redux</span>{' '}
				library.
			</p>
			<div className='code'>
				<CodeMirror
					language='javascript'
					theme={DarkTheme}
					extensions={extensions}
					readOnly={true}
					linenumbers="true"
					styleactiveline="true"
					value={codestringI}
				/>
			</div>

			<p>
				Add a provider to the root of your app{' '}
				<span className='commands'>(src/index.js)</span>. Enable redux to be
				available to the entire application. Import redux store and provide it
				as a prop to the Provider. It Allows components to access stores and
				dispatch actions.
			</p>

			<div className='code'>
				<CodeMirror
					language='javascript'
					theme={DarkTheme}
					extensions={extensions}
					readOnly={true}
					linenumbers="true"
					styleactiveline="true"
					value={codestringII}
				/>
			</div>

			<p>
				Create a slice reducer, that manages the tasks, for this article we’ll
				only add an action for deleting a task in an array of objects, adding a
				task and editing a task’s name.
			</p>

			<div className='code'>
				<CodeMirror
					language='javascript'
					theme={DarkTheme}
					extensions={extensions}
					readOnly={true}
					linenumbers="true"
					styleactiveline="true"
					value={codestringIII}
				/>
			</div>

			<p>
				To access the values in the task slice, react-redux provides a hook
				called <span className='commands'>‘useSelector’</span> that allows
				components to select and access states in redux stores. It allows
				effective and selective access of state in redux store. Example usage to
				access our tasks state might be.
			</p>

			<div className='code'>
				<CodeMirror
					language='javascript'
					theme={DarkTheme}
					extensions={extensions}
					readOnly={true}
					linenumbers="true"
					styleactiveline="true"
					value={codestringIV}
				/>
			</div>

			<p>
				To make changes to our tasks state in redux store, we’ll use the{' '}
				<span className='commands'>‘useDispatch’</span> hook from react-redux
				library. We use useDispatch to access the dispatch function in redux
				store. useDispatch returns a reference to dispatch function from redux
				store, the reference can be used to update states in redux store. It is
				a powerful tool that allows components to dispatch actions to update the
				state in the redux store, without having to pass the dispatch function
				down through multiple layers of props. Example usage of our tasks app:
				(the way the functions are designed are based on basic coding standards
				and personal preferences, in order to have correlation between the
				functions) . Please find complete functions implementations in the
				provided repo.
			</p>

			<div className='code'>
				<CodeMirror
					language='javascript'
					theme={DarkTheme}
					extensions={extensions}
					readOnly={true}
					linenumbers="true"
					styleactiveline="true"
					value={codestringV}
				/>
			</div>
			<p>
				React Redux is a library that combines the power of React and Redux to
				manage the state in a React application. React provides a powerful
				component-based architecture for building UIs, while Redux provides a
				predictable and centralised way to manage the state of an application.
			</p>

			<p>
				The core concepts of React Redux include the{' '}
				<span className='commands'>`Provider`</span> component, which wraps the
				root component of the application and makes the Redux store available to
				all components, and the <span className='commands'>`connect`</span>{' '}
				function, which connects a component to the Redux store and maps state
				and actions to its props.
			</p>

			<p>
				React Redux also provides a set of hooks, including{' '}
				<span className='commands'>`useSelector`</span> and{' '}
				<span className='commands'>`useDispatch`</span>, that allow components
				to access and dispatch actions to the Redux store selectively. These
				hooks provide a more efficient and flexible way to interact with the
				store than the <span className='commands'>`connect`</span> function.
			</p>

			<p>
				By using React Redux, developers can easily manage and update the state
				of their applications while maintaining a clear separation of concerns
				between the UI components and the state management. React Redux is a
				powerful tool for building scalable and maintainable React applications.
			</p>

			<div className='breakpoint' />
			<h2>Alternatives to react-redux:</h2>
			<div className='underline' />
			<ul>
				<li>Zustand</li>
				<li>Context API</li>
				<li>Recoil</li>
				<li>MobX</li>
				<li>RxJS</li>
			</ul>

			<div className='breakpoint' />

			<h2>What NOT to store in redux state management:</h2>
			<div className='underline' />
			<ul>
				<li>
					<strong>React component state:</strong> React component state should
					not be stored in the Redux store. The component state is specific to a
					component and does not need to be shared across the application.
					Instead, use the local state within the component.
				</li>
				<li>
					<strong>Non-serializable data:</strong> The data in the Redux store
					should be serializable, meaning it can be easily converted to a string
					and then back to its original form. Examples of non-serializable data
					include functions and Promises.
				</li>
				<li>
					<strong>Authentication tokens:</strong> Authentication tokens or other
					sensitive data should not be stored in the Redux store. Instead, use
					cookies or local storage to store this data securely.
				</li>
				<li>
					<strong>Large amounts of data:</strong> The Redux store should not be
					used as a database. Storing large amounts of data in the Redux store
					can negatively impact performance and increase the complexity of the
					application.
				</li>
			</ul>

			<h2>Key benefits of using React Redux:</h2>
			<div className='underline' />
			<ol>
				<li>
					<strong>Predictable state management:</strong> Redux provides a
					predictable and centralized way to manage the state of an application.
					Keeping the state in a single location makes it easier to reason about
					the application's behaviour and manage complex application states.
				</li>
				<li>
					<strong>Scalability:</strong> React Redux provides an architecture
					that scales well for applications of all sizes. By using Redux,
					developers can maintain a clear separation of concerns between the UI
					components and the state management, making adding new features and
					managing complex codebases easier.
				</li>
				<li>
					<strong>Large ecosystem:</strong> React Redux has a large and active
					ecosystem, with many plugins and libraries available to extend its
					functionality. This makes it easy to find and integrate with other
					libraries and tools that may be needed for a specific application.
				</li>
				<li>
					<strong>Performance optimizations:</strong> React Redux provides
					several performance optimizations that help reduce unnecessary
					re-renders and improve the application's overall performance. For
					example, React Redux uses a shallow comparison algorithm to compare
					the new and old state and props values, which can reduce the number of
					re-renders.
				</li>
				<li>
					<strong>Debugging tools:</strong> React Redux provides several useful
					debugging tools, such as the Redux DevTools, which allow developers to
					visualize the application's state and debug issues more easily.
				</li>
			</ol>
		</>
	);
};

export default MasteringReactRedux;
