import React, { useEffect } from 'react';
import ProjectCard from '../components/ProjectCard';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../styles/Portfolio.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import design1 from '../assets/designs/Food-Delivery-App.png';
import design2 from '../assets/designs/BlueLyne.png';
import design3 from '../assets/designs/Raycon.png';
import design4 from '../assets/designs/YourSound-Light.png';
import design5 from '../assets/designs/YourSound.png';
import design6 from '../assets/designs/Afroomerch.png';
import design7 from '../assets/designs/Jodorry.png';
import design8 from '../assets/designs/calculatorDS.png';

const Portfolio = () => {
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);

	return (
		<section
			className='portfolio-container'
			id='portfolio'
			data-aos='fade-right'
		>
			<p className='text'>PORTFOLIO</p>
			<h4 className='bold'>Latest Designs & Project</h4>

			<OwlCarousel
				className='carousel owl-carousel'
				loop
				responsive={{
					0: {
						items: 1,
					},
					480: {
						items: 1,
					},
					768: {
						items: 2,
					},
					992: {
						items: 3,
					},
				}}
			>
				<ProjectCard image={design1} label={'food-delivery-app'} link={''} />
				<ProjectCard image={design2} label={'Blue-Lyne-app'} link={''} />
				<ProjectCard
					image={design3}
					label={'raycon-web-app'}
					link={'https://rayconpoolcovers.com/'}
				/>
				<ProjectCard image={design4} label={'your-sound-light-app'} link={''} />
				<ProjectCard image={design5} label={'your-sound-dark-app'} link={''} />
				<ProjectCard image={design6} label={'afroomerch'} link={''} />
				<ProjectCard
					image={design7}
					label={'jodorry'}
					link={'https://jodorry.com/'}
				/>
				<ProjectCard image={design8} label={'calculator-ds'} link={''}
				/>
			</OwlCarousel>
		</section>
	);
};

export default Portfolio;
