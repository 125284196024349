import React, { useEffect, useContext } from 'react';
import Nav from '../components/Nav';
import '../styles/Blog.css';
import portrait from '../assets/images/placeholder.png';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../navigation/AuthProvider';
import { posted } from '../blogs/bloglist';

const Blog = () => {
	const { theme } = useContext(AuthContext);
	const navigate = useNavigate();
	useEffect(() => {
		document.title = `Blogs | Syntax Tech Solutions`;
		window.scrollTo(0, 0);
	}, []);

	const onNavigate = (blogId) => {
		navigate(`/blog/${blogId}`);
	};

	const loadTags = (tags) => {
		if (tags === undefined) {
			return;
		} else {
			let tagList = '';
			for (let i = 0; i < tags.length; i++) {
				if (tags.length === tags.length - 1) {
					tagList += `${tags[i]}`;
				} else {
					tagList += `${tags[i]}, `;
				}
			}

			return tagList;
		}
	};

	const getALlTagsAndCount = () => {
		let allTags = [];
		let tagCount = {};
		for (let i = 0; i < posted.length; i++) {
			if (posted[i].tag !== undefined) {
				for (let j = 0; j < posted[i].tag.length; j++) {
					allTags.push(posted[i].tag[j]);
				}
			}
		}

		for (let i = 0; i < allTags.length; i++) {
			if (tagCount[allTags[i]] === undefined) {
				tagCount[allTags[i]] = 1;
			} else {
				tagCount[allTags[i]] += 1;
			}
		}

		return tagCount;
	};

	const CreateTagList = () => {
		let tagList = [];
		let tagCount = getALlTagsAndCount();
		for (const [key, value] of Object.entries(tagCount)) {
			tagList.push(
				<div className='tag'>
					<span>
						{key} ({value})
					</span>
				</div>
			);
		}

		return tagList;
	};

	return (
		<>
			<Nav />
			<main className='blog-container'>
				<div
					className={
						theme === 'dark-theme' ? 'blog-content' : 'blog-content light'
					}
				>
					<div className='heading'>
						<h1>Syntax Tech Soluton Blog</h1>
						<div className='tags'>
							<div className='tags'>{<CreateTagList />}</div>
						</div>
					</div>
				</div>
				<div className='blogs'>
					{posted.length > 0 &&
						posted.map((blog, index) => (
							<div
								className='blog'
								onClick={() => onNavigate(blog.id)}
								key={index}
							>
								<div className='img-container'>
									<img
										src={blog !== undefined ? blog.image : portrait}
										alt='blog'
									/>
								</div>
								<div className='blog-info'>
									<div className='blog-author-info'>
										<div className='writer-img'>
											<img
												src={blog !== undefined ? blog.author.image : portrait}
												alt='author'
											/>
										</div>
										<p>{blog !== undefined ? blog.author.name : 'Writer not found'} | {blog !== undefined ? blog.date : 'Article not found'}</p>
									</div>
									<h1>
										{blog !== undefined ? blog.title : 'Article not found'}
									</h1>
									<p>{blog !== undefined ? loadTags(blog.tag) : 'Tag'}</p>
									<p>{blog !== undefined ? blog.readtime : '0 min read'}</p>
								</div>
							</div>
						))}
				</div>
			</main>
			<Footer />
		</>
	);
};

export default Blog;
