import React from 'react';
import './styles/Button.css';

const Button = ({ title, onPressed }) => {
	return (
		<div className='button-container' onClick={onPressed}>
			<p className='text'>{title}</p>
		</div>
	);
};

export default Button;
