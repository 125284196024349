import React from 'react';
import './styles/ProjectCard.css';
import { RxExternalLink } from 'react-icons/rx';

const ProjectCard = ({ image, label, link }) => {
	return (
		<div className='project-card-container'>
			{link !== '' && (
				<div
					className='icon-container'
					onClick={() => window.open(link, '_blank')}
				>
					<RxExternalLink className='icon' />
				</div>
			)}

			<div className='design'>
				<img src={image} alt={label} className='image' />
			</div>
		</div>
	);
};

export default ProjectCard;
