const tsholofelo = require('../../assets/images/authors/tsholofelo.png');
const aaron = require('../../assets/images/authors/aaron.png');

export const authors = [
	{
		id: 'tsholofelo',
		name: 'Tsholofelo D. Mokheleli',
		image: tsholofelo,
		link: 'https://www.linkedin.com/in/tsholofelo-mokheleli/',
		description: `I'm a Master's student at the University of Johannesburg specializing in IT management. I hold three qualifications, a diploma and advanced diploma in Business Information Technology and a BCom Honours in Information Systems. I'm also a strategic tutor, teaching software development subjects. I love and enjoy web and app development, and I'm good at it! Recently, I've been diving into data science and machine learning, building models to explore new possibilities and drive innovation in the field. Follow me on`,
	},
	{
		id: 'aaron',
		name: 'Aaron Kibambe',
		image: aaron,
		link: 'https://www.linkedin.com/in/aaron-kibambe-8b5ba71a8/',
		description: `I am a software engineer with a passion for AI and statistics. With a love for cleaning data, building models, and solving problems, I have a keen eye for detail and a thirst for knowledge. I am an avid reader of articles, always keeping up-to-date with the latest advancements in the field. My love for computers and coding drives my work as a software engineer, where I bring my expertise and enthusiasm to every project. With a dedication to excellence and a desire to continuously learn and grow, I am a valuable asset to any team. Follow me on`,
	},
];
