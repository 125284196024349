export const codestrings = [
    {
        id: 'how-to-handle-keyboard-events-in-react',
        code: `import React from "react";

const MyComponent = () => {
    const handleKeyDown = (event) => {
        // Handle the keyboard event here
        console.log("Key code:", event.keyCode);
    };
    
    return (
        <div>
            <h1>My Component</h1>
            <input type="text" onKeyDown={handleKeyDown} />
        </div>
    );
};

export default MyComponent;`,
    },
    {
        id: 'props-vs-state-in-react-one',
        code: `// Parent Component
import React from "react";
import ChildComponent from "./ChildComponent";

const ParentComponent = () => {
    const name = "John";
    const age = 25;

    return (
        <div>
        <h1>Parent Component</h1>
        <ChildComponent name={name} age={age} />
        </div>
    );
    };

    // Child Component
    const ChildComponent = (props) => {
    return (
        <div>
        <h2>Child Component</h2>
        <p>Name: {props.name}</p>
        <p>Age: {props.age}</p>
        </div>
    );
};

export default ParentComponent;`,
    },
    {
        id: 'props-vs-state-in-react-two',
        code: `import React, { useState } from "react";

const CounterComponent = () => {
const [count, setCount] = useState(0);

const increment = () => {
    setCount(count + 1);
};

const decrement = () => {
    setCount(count - 1);
};

return (
    <div>
    <h1>Counter Component</h1>
    <p>Count: {count}</p>
    <button onClick={increment}>Increment</button>
    <button onClick={decrement}>Decrement</button>
    </div>
);
};

export default CounterComponent;`,
    },
    {
        id: 'mastering-react-redux-i',
        code: `import { configureStore } from "@reduxjs/toolkit";
import taskReducer from "./slices/tasks";


export default configureStore({
    reducer: {
        tasks: taskReducer,
    },
});`,
    },
    {
        id: 'mastering-react-redux-ii',
        code: `. . .
import { Provider } from "react-redux";
import store from "./redux_/store";
. . .


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <React.StrictMode>
            {/* your code goes here */}
        </React.StrictMode>
    </Provider>
);`,
    },
    {
        id: 'mastering-react-redux-iii',
        code: `import { createSlice } from "@reduxjs/toolkit";

const initialState = { tasks: [] };

const taskSlice = createSlice({
    name: "tasks",
    initialState,
    reducers: {
        addTask: (state, action) => {
            // add a new task to the array.
            state.tasks.push(action.payload);
        },
        deleteTask: (state, action) => {
            // return all tasks objects in array and assign them to store variable tasks, except the ones with the same index as the provided index.
            state.tasks = state.tasks.filter(
                (task, index) => index !== action.payload
            );
        },
        editTaskName: (state, action) => {
            // find index in array where the provided index is the same.
            let task = state.tasks.filter(
                (task, index) => index === action.payload.index
            );
            // if the index is not equal to -1, it finds a task with the right index.
            // change the task name to the new name.
            if (task.length === 1) {
                state.tasks[action.payload.index].name = action.payload.name;
            }
        },
    },
});


// export actions for each reducer.
export const { addTask, deleteTask, editTaskName } = taskSlice.actions;
// provide a way to access our tasks state
export const selectTasks = (state) => state.tasks.tasks;
// export the reducer
export default taskSlice.reducer; `,
    },
    {
        id: 'mastering-react-redux-iv',
        code: `. . .
import { useSelector } from "react-redux"; // import useSelector
// import the provided function from tasksSlice to access our states.
import { selectTasks } from "./redux_/slices/tasks";
. . .

export default function ViewComponent() {
. . .
// pass function selectTasks that represent the state of taskSlice.
// useSelector will render the components when
// select Tasks state changes, in redux store.
const selectTask = useSelector(selectTasks);
. . .


    return (
        <div className="view">
            . . .
            {/* displaying all task object in our redux store using map function of arrays */}
            <div className="w-4/12 mx-auto">
            {selectTask &&
                selectTask.length > 0 &&
                selectTask.map((task, index) => (
                    <div
                        className="my-2 bg-slate-400  flex justify-between px-2 py-2 text-center"
                        key={index}
                    >
                        <div className=" underline " >{task?.name}</div>
                    </div>
                ))}
            </div>
            . . .
        </div>
    );
}`,
    },
    {
        id: 'mastering-react-redux-v',
        code: `import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/";
import {
    addTask,
    deleteTask,
    editTaskName,
    selectTasks,
} from "./redux_/slices/tasks";
. . .

function App() {
    . . .
    const [formData, setFormData] = useState({});
    const [toEdit, setToEdit] = useState(null);
    // useDispatch returns reference to dispatch function from redux store.
    const dispatch = useDispatch();
    . . .

    function edit(newTask, index) {
        // update state of new task to edit.
        // pass object with index. and the new task's name.
        // there are many ways to implement this code.
        setToEdit({ index: index, name: newTask?.name });
    }

    function handleEdit(event) {
        // update task name in redux store state.
        // prevent form from reloading page
        // make sure the form is filled.
        // make sure, task edit name is not duplicated.
        // pass form data to editTaskName actions reducer.
        dispatch(editTaskName(toEdit));
    }

    function removeTask(index) {
        // pass the task index to delete the task.
        dispatch(deleteTask(index));
    }

    function handleSubmit(event) {
        // prevent form from reloading the page, on submit.
        // add a task to reduce the slice.
        // prevent adding tasks with the same name.
        // please check the repo for complete code.
        dispatch(
            addTask({
            name: formData?.name,
            })
        );
        // erase form.
    }

    return (
        <div className="App">
            . . .
        </div>
    );
}

export default App;`,
    },
    {
        id: 'AbstractionCode',
        code: `public abstract class Vehicle
{
    public abstract void Accelerate();
    public abstract void Brake();
    // Other common vehicle properties and methods
}

public class Car : Vehicle
{
    public override void Accelerate()
    {
        Console.WriteLine("The car is accelerating.");
    }
    
    public override void Brake()
    {
        Console.WriteLine("The car is braking.");
    }
}     
`
    }, {
        id: 'EncapsulationCode',
        code: `public class BankAccount
{
    private decimal balance;

    public decimal Balance
    {
        get { return balance; }
        private set { balance = value; }
    }

    public void Deposit(decimal amount)
    {
        // Code for depositing money
        balance += amount;
    }

    public void Withdraw(decimal amount)
    {
        // Code for withdrawing money
        if (balance >= amount)
            balance -= amount;
        else
            Console.WriteLine("Insufficient funds!");
    }
}`
    }, {
        id: 'InheritanceCode',
        code: `public class Animal
{
    public virtual void MakeSound()
    {
        Console.WriteLine("The animal makes a sound.");
    }
}

public class Dog : Animal
{
    public override void MakeSound()
    {
        Console.WriteLine("The dog barks.");
    }
}

public class Cat : Animal
{
    public override void MakeSound()
    {
        Console.WriteLine("The cat meows.");
    }
}
        `
    }, {
        id: 'PolymorphismCode',
        code: `public class Shape
{
    public virtual void Draw()
    {
        Console.WriteLine("Drawing a shape.");
    }
}

public class Circle : Shape
{
    public override void Draw()
    {
        Console.WriteLine("Drawing a circle.");
    }
}

public class Square : Shape
{
    public override void Draw()
    {
        Console.WriteLine("Drawing a square.");
    }
}     
`
    }
];
