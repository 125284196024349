import Provider from './navigation';
import firebase from 'firebase';
import './App.css';

require('firebase/firestore');

const firebaseConfig = {
	apiKey: 'AIzaSyBY7MxScwO0EAxkSeBMEmIR0pwHKd0b7vg',
	authDomain: 'syntax-e970d.firebaseapp.com',
	projectId: 'syntax-e970d',
	storageBucket: 'syntax-e970d.appspot.com',
	messagingSenderId: '584357552406',
	appId: '1:584357552406:web:b0dee51432406f3f15b037',
	measurementId: 'G-DT1QF8TMD9',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

function App() {
	return <Provider />;
}

export default App;
