import React, { useEffect, useContext } from 'react';
import ServiceCard from '../components/ServiceCard';
import image1 from '../assets/images/ux-interface.png';
import image2 from '../assets/images/app-development.png';
import image3 from '../assets/images/coding.png';
import image4 from '../assets/images/cloud.png';

import image5 from '../assets/images/ux-interface-light.png';
import image6 from '../assets/images/app-development-light.png';
import image7 from '../assets/images/coding-light.png';
import image8 from '../assets/images/cloud-light.png';

import '../styles/Services.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { AuthContext } from '../navigation/AuthProvider';

const Services = () => {
	const { theme } = useContext(AuthContext);
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);

	return (
		<section className='services-container' id='services' data-aos='fade-left'>
			<p className='text'>OUR SERVICES</p>
			<h4 className='bold'>
				Save Time Managing Your Business with Our Best Services
			</h4>
			<div className='flex'>
				<ServiceCard
					image={theme === 'dark-theme' ? image1 : image5}
					label={'UX/UI Designer'}
					description={
						'We focus on the look, feel, Aesthetic, and minimalist of the website and apps when we design.'
					}
				/>
				<ServiceCard
					image={theme === 'dark-theme' ? image2 : image6}
					label={'App Development'}
					description={
						'We develop mobile apps that follow material design guidelines for visual and navigation patterns.'
					}
				/>
				<ServiceCard
					image={theme === 'dark-theme' ? image3 : image7}
					label={'Web Development'}
					description={
						'We develop the best, usable, professional websites using the latest W3C web standards guidelines.'
					}
				/>
				<ServiceCard
					image={theme === 'dark-theme' ? image4 : image8}
					label={'Hosting & Domain'}
					description={
						'We host with the best Hosting site. And register a domain name and custom domain emails for your business.'
					}
				/>
			</div>
		</section>
	);
};

export default Services;
