import React, { useEffect, useState } from 'react';
import '../styles/Hire.css';
import Button from '../components/Button';
import AOS from 'aos';
import 'aos/dist/aos.css';
import FormModal from '../components/FormModal';

const Hire = () => {
	const [visible, setVisible] = useState(false);

	const onPressed = () => {
		setVisible(!visible);
	};

	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);

	return (
		<>
			<section className='hire-container' id='contact' data-aos='fade-right'>
				<h4 className='bold'>You Want To Grow Your Business ?</h4>
				<p className='text'>
					We work with brands, startups, to Small and Medium-sized Enterprises.
					Collaborate for more impact and growth. Join us and let us help you
					grow your business.
				</p>
				<Button title={'HIRE US'} onPressed={() => onPressed()} />
			</section>
			<FormModal close={visible} />
		</>
	);
};

export default Hire;
