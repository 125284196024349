import React from 'react';
import '../styles/Footer.css';
import { animateScroll as scroll } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
	const navigate = useNavigate();
	const scrollTop = () => {
		scroll.scrollToTop();
	};

	const onNavigate = (path) => {
		navigate(path, { replace: true });
	};

	return (
		<footer className='footer'>
			<div className='container'>
				<div className='left'>
					<h1 onClick={scrollTop}>SYNTAX TECH SOLUTIONS</h1>
					<div className='contact-boxes'>
						<div className='contact'>
							<p>Send Us An Email</p>
							<h6>info@syntaxtechsolutions.com</h6>
						</div>
						<div className='contact'>
							<p>Give Us a Call</p>
							<h6>+27 74 226 7496</h6>
						</div>
					</div>
				</div>
				<div className='right'>
					<div className='tabs'>
						<h3>Menu</h3>
						<ul>
							<li onClick={() => onNavigate('/')}>Our Services</li>
							<li onClick={() => onNavigate('/')}>Portfolio</li>
							<li onClick={() => onNavigate('/')}>About Us</li>
							<li onClick={() => onNavigate('/')}>Contact Us</li>
						</ul>
					</div>
					<div className='tabs'>
						<h3>Social Media</h3>
						<ul>
							<li
								onClick={() =>
									window.open(
										'https://www.instagram.com/tsholofelo_mokheleli/',
										'_blank'
									)
								}
							>
								Instagram
							</li>
							<li
								onClick={() =>
									window.open(
										'https://www.linkedin.com/in/tsholofelo-mokheleli/',
										'_blank'
									)
								}
							>
								LinkedIn
							</li>
							<li
								onClick={() =>
									window.open(
										'https://github.com/tsholofelo-mokheleli',
										'_blank'
									)
								}
							>
								Github
							</li>
							<li
								onClick={() =>
									window.open(
										'https://dribbble.com/syntaxtechsolutions',
										'_blank'
									)
								}
							>
								Dribbble
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='footer-bottom'>
				<p className='text'>
					© {new Date().getFullYear()} Syntax Tech Solutions | All rights
					reserved. |{' '}
					<span onClick={() => window.open('/terms', '_blank')}>
						Terms Of Service
					</span>
				</p>
			</div>
		</footer>
	);
};

export default Footer;
