import React from 'react';

import CodeMirror from '@uiw/react-codemirror';
import { createTheme } from '@uiw/codemirror-themes';
import { javascript } from '@codemirror/lang-javascript';
import { tags as t } from '@lezer/highlight';

const DarkTheme = createTheme({
	theme: 'dark',
	settings: {
		background: '#1e1e1e',
		foreground: '#e6e6e6',
		caret: '#9f9f9f',
		selection: '#add6ff33',
		selectionMatch: '#add6ff33',
		lineHighlight: '#3f3f3f',
		gutterBackground: '#1e1e1e',
		gutterForeground: '#9f9f9f',
	},
	styles: [
		{ tag: t.comment, color: '#6a9955' },
		{ tag: t.variableName, color: '#9cdcfe' },
		{ tag: [t.string, t.special(t.brace)], color: '#ce9178' },
		{ tag: t.number, color: '#b5cea8' },
		{ tag: t.bool, color: '#4ec9b0' },
		{ tag: t.null, color: '#4ec9b0' },
		{ tag: t.keyword, color: '#c586c0' },
		{ tag: t.operator, color: '#d4d4d4' },
		{ tag: t.className, color: '#4ec9b0' },
		{ tag: t.definition(t.typeName), color: '#4ec9b0' },
		{ tag: t.typeName, color: '#4ec9b0' },
		{ tag: t.angleBracket, color: '#d4d4d4' },
		{ tag: t.tagName, color: '#c586c0' },
		{ tag: t.attributeName, color: '#9cdcfe' },
	],
});
const extensions = [javascript({ jsx: true })];

const HandleKeywordEvents = ({ codestring }) => {
	return (
		<>
			<div className='breakpoint' />
			<p>
				Keyboard events are an important aspect of web development, allowing
				users to interact with web applications using their keyboards. In React,
				handling keyboard events used to be done using the{' '}
				<span className='commands'>onKeyPress</span> event handler. However, as
				of React version 17, <span className='commands'>onKeyPress</span> has
				been deprecated in favor of a more modern approach to handling keyboard
				events. In this blog article, we will explore the new event handling
				approach in React, including code examples.
			</p>
			<div className='breakpoint' />
			<h2>What's Deprecated?</h2>
			<div className='underline' />
			<p>
				In React version 17, the <span className='commands'>onKeyPress</span>{' '}
				event handler is deprecated, meaning that it is no longer recommended to
				use it in new projects. While it may still work in existing projects, it
				is not considered a best practice going forward. The reason for this
				deprecation is that <span className='commands'>onKeyPress</span> is not
				consistent across different browsers and can lead to inconsistent
				behavior, especially when it comes to handling special keys like "Enter"
				and "Esc". React recommends using a different approach for handling
				keyboard events that is more reliable and consistent.
			</p>
			<h2>What's the New Approach?</h2>

			<p>
				The new approach for handling keyboard events in React is to use the
				onKeyDown event handler instead of{' '}
				<span className='commands'>onKeyPress</span>. The{' '}
				<span className='commands'>handleKeyDown</span> event is fired when a
				key is first pressed down, and it provides a more consistent way of
				capturing keyboard events across different browsers and handling special
				keys.
			</p>

			<p>
				Here's an example of how you can use the onKeyDown event handler in
				React:
			</p>
			<div className='code'>
				<CodeMirror
					language='javascript'
					theme={DarkTheme}
					extensions={extensions}
					readOnly={true}
					linenumbers="true"
					styleactiveline="true"
					value={codestring}
				/>
			</div>

			<p>
				In the above example, we define a functional component called{' '}
				<span className='commands'>MyComponent</span> that includes an input
				element. We attach the <span className='commands'>onKeyDown</span> event
				handler to the input element and pass in a callback function{' '}
				<span className='commands'>handleKeyDown</span> as the event handler.
				Inside the <span className='commands'>handleKeyDown</span> function, we
				can access the <span className='commands'>event</span> object and
				extract relevant information, such as the key code of the pressed key
				using <span className='commands'>event.keyCode</span>.
			</p>

			<h2>Advantages of the New Approach:</h2>

			<p>
				The new approach of using <span className='commands'>onKeyDown</span>{' '}
				event handler in React has several advantages over the deprecated{' '}
				<span className='commands'>onKeyPress</span> event handler:
			</p>
			<ol>
				<li>
					Consistency: The <span className='commands'>onKeyDown</span> event
					handler provides consistent behavior across different browsers, making
					your application more reliable and predictable.
				</li>
				<li>
					Special Key Handling: The <span className='commands'>onKeyDown</span>{' '}
					event handler allows for more reliable handling of special keys like
					"Enter" and "Esc", which can be problematic with{' '}
					<span className='commands'>onKeyPress</span> due to inconsistencies
					across browsers.
				</li>
				<li>
					Future-proofing: Since <span className='commands'>onKeyPress</span> is
					deprecated, using the <span className='commands'>onKeyDown</span>{' '}
					event handler ensures that your code will be future-proof and won't
					break in future versions of React.
				</li>
			</ol>

			<div className='breakpoint' />
			<p>
				In conclusion, handling keyboard events in React has been updated with
				the deprecation of <span className='commands'>onKeyPress</span> and the
				recommendation to use <span className='commands'>onKeyDown</span> event
				handler instead. The <span className='commands'>onKeyDown</span> event
				handler provides a more consistent and reliable way of handling keyboard
				events in React, especially when it comes to special keys. By adopting
				the new approach, you can ensure that your React applications are more
				robust and future-proof.
			</p>
		</>
	);
};

export default HandleKeywordEvents;
