import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Nav from '../sections/Nav';
import Main from '../sections/Main';
import Services from '../sections/Services';
import Portfolio from '../sections/Portfolio';
import About from '../sections/About';
import Hire from '../sections/Hire';
import Footer from '../sections/Footer';
import Blog from '../sections/Blog';
import Article from '../sections/Article';
import Terms from '../sections/Terms';
import VibesCheck from '../sections/VibesCheck';

const Syntax = () => {
	useEffect(() => {
		document.title = `Syntax Tech Solutions`;
	}, []);
	return (
		<main>
			<Nav />
			<Main />
			<Services />
			<Portfolio />
			<About />
			<Hire />
			<Footer />
		</main>
	);
};

const AppStack = () => {
	return (
		<>
			<Router>
				<Routes>
					<Route path='/' exact element={<Syntax />} />
					<Route path='/terms' element={<Terms />} />
					<Route path='/vibescheck' element={<VibesCheck />} />
					<Route path='/blog' element={<Blog />} />
					<Route path='/blog/:blogId' element={<Article />} />

					<Route path='*' element={<Syntax />} />
				</Routes>
			</Router>
		</>
	);
};

export default AppStack;
