import React, { useState, useContext } from 'react';
import { Colors } from '../utils/utility';
import { animateScroll as scroll } from 'react-scroll';
import { AuthContext } from '../navigation/AuthProvider';
import { useNavigate } from 'react-router-dom';
import '../styles/Nav.css';

import Icon from '@mdi/react';
import {
	mdiMenu,
	mdiClose,
	mdiMoonWaningCrescent,
	mdiWhiteBalanceSunny,
} from '@mdi/js';
import dark from '../assets/images/dark.png';
import light from '../assets/images/light.png';

const NavII = () => {
	const { theme, toggleTheme } = useContext(AuthContext);
	const [click, setClick] = useState(false);
	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => setClick(!click);
	const navigate = useNavigate();

	const scrollTop = () => {
		closeMobileMenu();
		scroll.scrollToTop();
	};

	const onNavigate = (path) => {
		setClick(!click)
		navigate(path, { replace: true });
	};

	return (
		<nav className='navbar'>
			<div className='container'>
				<div className='logoContainer' onClick={scrollTop}>
					<img
						src={theme === 'dark-theme' ? dark : light}
						alt='Logo'
						className='logo'
					/>
				</div>
				<Icon
					path={click ? mdiClose : mdiMenu}
					title='menu'
					color={theme === 'dark-theme' ? Colors.white : Colors.dark}
					className='menuIcon'
					onClick={handleClick}
				/>
				<ul className={click ? 'navMenu active' : 'navMenu'}>
					<li className='nav-item'>
						<div onClick={() => onNavigate('/')} className='navLinks'>
							Our Services
						</div>
					</li>
					<li className='nav-item'>
						<div onClick={() => onNavigate('/')} className='navLinks'>
							Portfolio
						</div>
					</li>
					<li className='nav-item'>
						<div onClick={() => onNavigate('/')} className='navLinks'>
							About
						</div>
					</li>

					<li className='nav-item'>
						<div onClick={() => onNavigate('/')} className='navLinks'>
							Contact
						</div>
					</li>
					<li className='nav-item'>
						<div onClick={() => onNavigate('/blog')} className='navLinks'>
							Blog
						</div>
					</li>

					<li className='nav-item-theme'>
						<p>Dark</p>
						<div className='themeSwitch' onClick={() => toggleTheme()}>
							{theme === 'dark-theme' ? (
								<Icon
									path={mdiMoonWaningCrescent}
									title='dark'
									size={'24px'}
									color={Colors.light}
								/>
							) : (
								<div className='ball' />
							)}

							{theme === 'dark-theme' ? (
								<div className='ball' />
							) : (
								<Icon
									path={mdiWhiteBalanceSunny}
									title='light'
									size={'24px'}
									color={Colors.light}
								/>
							)}
						</div>
						<p className='text'>Light</p>
					</li>
				</ul>
				<div className='themeContainer'>
					<p>Dark</p>
					<div className='themeSwitch' onClick={() => toggleTheme()}>
						{theme === 'dark-theme' ? (
							<Icon
								path={mdiMoonWaningCrescent}
								title='dark'
								size={'24px'}
								color={Colors.light}
							/>
						) : (
							<div className='ball' />
						)}

						{theme === 'dark-theme' ? (
							<div className='ball' />
						) : (
							<Icon
								path={mdiWhiteBalanceSunny}
								title='light'
								size={'24px'}
								color={Colors.light}
							/>
						)}
					</div>
					<p className='text'>Light</p>
				</div>
			</div>
		</nav>
	);
};

export default NavII;
