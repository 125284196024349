import { authors } from './authors/list';
import { codestrings } from './code/list';

// Images
const handleKeyboardEvents = require('../assets/images/blogs/how-to-handle-keyboard-events-in-react.png');
const propsAndState = require('../assets/images/blogs/props-vs-state-in-react.png');
const masteringReactRedux = require('../assets/images/blogs/mastering-react-redux.png');
const exploringOOP = require('../assets/images/blogs/exploring-object-oriented-programming.png');

// read time is in minutes = total words count / 200

export const posted = [
	{
		id: 'exploring-object-oriented-programming',
		title: 'Exploring Object-Oriented Programming (OOP) Made Easy with C#: A Beginner\'s Guide for Students',
		image: exploringOOP,
		date: 'July 18, 2023',
		author: authors[0],
		codestring: [codestrings[8], codestrings[9], codestrings[10], codestrings[11]],
		tag: ['Object-Oriented Programming OPP', 'Beginner C# Tutorials'],
		code: 'ExploringObjectOrientedProgramming',
		readtime: '6 min read'
	},
	{
		id: 'mastering-react-redux',
		title:
			'Mastering React Redux: Best Practices for State Management that Will Streamline Your Development Process',
		image: masteringReactRedux,
		date: 'May 15, 2023',
		author: authors[1],
		codestring: [
			codestrings[3],
			codestrings[4],
			codestrings[5],
			codestrings[6],
			codestrings[7],
		],
		tag: ['React Redux', 'React State management'],
		code: 'MasteringReactRedux',
		readtime: '9 min read'
	},
	{
		id: 'how-to-handle-keyboard-events-in-react',
		title:
			'How to Handle Keyboard Events in React: Best Practices and Code Examples',
		image: handleKeyboardEvents,
		date: 'April 09, 2023',
		author: authors[0],
		codestring: codestrings[0],
		tag: ['Beginner React Tutorials', 'React Event Handlers'],
		code: 'HandleKeywordEvents',
		readtime: '3 min read'
	},
	{
		id: 'props-vs-state-in-react',
		title: 'Props vs. State in React: Differences and Code Examples',
		image: propsAndState,
		date: 'April 09, 2023',
		author: authors[0],
		codestring: [codestrings[1], codestrings[2]],
		tag: ['Beginner React Tutorials', 'React Basics'],
		code: 'PropsAndState',
		readtime: '3 min read'
	}
];
